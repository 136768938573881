<template>
  <v-container fluid class="pa-8">
    <v-row class="pb-4">
      <GsNavButtons />
    </v-row>
    <v-row>
      <v-col cols="4" class="flex-grow-1 pl-0">
        <v-progress-circular
          v-if="isLoading"
          indeterminate
          color="primary"
        ></v-progress-circular>
        <v-tabs v-model="selectedTab" vertical>
          <v-tab
            v-for="tab in tabs"
            :key="tab"
            class="d-flex flex-column align-start flex-wrap"
          >
            <span v-if="hasTranslation(`tagLabel:${tab}`)">
              {{ $t(`tagLabel:${tab}`) }}
            </span>
            <span v-if="!hasTranslation(`tagLabel:${tab}`)">
              {{ tab }}
            </span>
            <span
              class="caption grey--text"
              v-if="hasTranslation(`tagDesc:${tab}`)"
            >
              {{ $t(`tagDesc:${tab}`) }}
            </span>
          </v-tab>
        </v-tabs>
      </v-col>
      <v-col cols="5">
        <v-tabs-items v-model="selectedTab">
          <v-tab-item v-for="(tab, i) in tabs" :key="tab">
            <v-card>
              <NGTTree
                :topLevelIndex="i"
                :loadParams="loadParams"
                selCmp="checkbox"
                @highlight:el="onHighlightEl"
              />
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import GsTemplateTags from "@/components/GsTemplateTags";
import NGTTree from "@/components/NGT/NGTTree";
import GsNavButtons from "@/components/GsNavButtons";

export default {
  name: "EntityTags",
  components: {
    GsTemplateTags,
    NGTTree,
    GsNavButtons
  },
  data() {
    return {
      isLoading: false,
      tags: null,
      sortBy: ["tag"],
      sortDesc: [false],
      selectedTab: 0,
      entityName: null,
      entityId: null
    };
  },
  computed: {
    tabs() {
      return this.$store.state.entityTags.items.map(p => p.name);
    },
    collection() {
      switch (this.entityName) {
        case "identities":
          return "identity";
        case "data-providers":
          return "dataProvider";
        case "clients":
          return "client";
        case "resource-servers":
          return "resourceServer";
        case "data-stores":
          return "dataStore";
      }
      return null;
    },
    loadParams() {
      return {
        entityId: this.entityId,
        entityName: this.entityName,
        collection: this.collection
      };
    },
    selectedTabText() {
      if (this.tabs.length === 0) return "";
      const label = `${this.tabs[this.selectedTab]}`;
      return this.hasTranslation(`tagLabel:${label}`)
        ? this.$t(`tagLabel:${label}`)
        : label;
    }
  },
  watch: {
    selectedTabText(v) {
      this.$store.commit("pageSub", [
        {
          text: v,
          disabled: true
        }
      ]);
    },
    $route: {
      immediate: true,
      handler(v) {
        const p = this.$route.path.split("/");
        this.entityName = p[1];
        this.entityId = p[2];
        // console.log(`${this.entityName} ${this.entityId}`);
        this.init();
      }
    }
  },
  async mounted() {
    this.$store.commit("pageSub", [
      {
        text: this.selectedTabText,
        disabled: true
      }
    ]);
  },
  beforeDestroy() {
    this.$store.commit("pageSub", null);
  },
  methods: {
    async init() {
      await this.setBreadCrumbs();
      await this.loadData();
    },
    async loadData() {
      try {
        this.isLoading = true;
        await this.$store.dispatch("entityTags/load", this.loadParams);
      } catch (error) {
        this.error = "Error";
      } finally {
        this.isLoading = false;
      }
    },
    hasTranslation(key) {
      return this.$te(key);
    },
    onHighlightEl(label) {
      if (!label) {
        this.$store.commit("pageSub", [
          {
            text: this.selectedTabText,
            disabled: true
          }
        ]);
        return;
      }

      let groups = label.split(":");
      groups.pop();

      let bread = [];
      for (let group of groups) {
        const label = `tagLabel:${group}`;
        const text = this.hasTranslation(label) ? this.$t(label) : group;
        bread.push({
          text,
          disabled: true
        });
      }
      this.$store.commit("pageSub", bread);
    }
  }
};
</script>
