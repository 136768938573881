<template>
  <div class="px-4 d-flex flex-row align-center">
    <div class="d-flex flex-column">
      <span>{{ $t(`tagLabel:${tagGroup}`) }}:</span>
      <span
        class="caption grey--text"
        v-if="hasTranslation(`tagDesc:${tagGroup}`)"
        >{{ $t(`tagDesc:${tagGroup}`) }}</span
      >
    </div>
    <v-select
      :items="tags"
      :label="$t('addNewTag')"
      v-model="selectedTag"
      class="ml-6"
    ></v-select>
    <v-btn icon @click="addTag()" class="ml-4">
      <v-icon>mdi-plus</v-icon>
    </v-btn>
    <!-- <v-chip
      v-for="(tag, i) in tags"
      :key="i"
      color="primary"
      outlined
      class="ml-2 my-2"
      @click="tagChip_click(tag)"
      >{{ tag }}</v-chip
    > -->
  </div>
</template>

<script>
const { callAPI } = require("ngt-frontend-core").apiOpsBff;

export default {
  name: "GsTemplateTags",
  props: {
    tagGroup: {
      type: String,
      default: null
    },
    availableTags: {
      type: Array,
      default: null
    }
  },
  data() {
    return {
      selectedTag: null
    };
  },
  computed: {
    tags() {
      let tags = this.availableTags;

      tags = tags.filter(p => p.startsWith(`${this.tagGroup}:`));

      tags = tags.map(p => {
        const lastColonIndex = p.lastIndexOf(":");
        return p.substring(lastColonIndex + 1);
      });

      tags.sort((a, b) => a.localeCompare(b));

      return tags;
    }
  },
  mounted() {},
  methods: {
    async addTag() {
      if (!this.tagGroup) return;
      if (!this.selectedTag) return;
      const tag = `${this.tagGroup}:${this.selectedTag}`;
      // console.log(tag);
      this.$emit("addTag", tag);
    },
    hasTranslation(key) {
      return this.$te(key);
    },
    tagChip_click(tag) {
      this.selectedTag = tag;
      this.addTag();
    }
  }
};
</script>
