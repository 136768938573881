<template>
  <div>
    <v-progress-circular
      v-if="isLoading"
      indeterminate
      color="primary"
    ></v-progress-circular>

    <v-treeview
      ref="treeview"
      v-if="!isLoading"
      :items="items"
      :active="activeItems"
      :open="openedItems"
      @update:active="onActive"
      @update:open="onOpen"
      item-key="id"
      item-text="name"
      open-on-click
      dense
      activatable
      hoverable
    >
      <template v-slot:label="{ item }">
        <div :data-key="item.id" @mouseover="highlightElement(item.id)">
          <span v-if="!hasTranslation(`tagLabel:${item.name}`)">
            {{ item.name }}
          </span>
          <span v-if="hasTranslation(`tagLabel:${item.name}`)">
            {{ $t(`tagLabel:${item.name}`) }}
          </span>
          <span v-if="!item.isLeaf" class="caption">
            ( {{ item.numOfChildrens }} / {{ item.numOfSelectedChildrens }} )
          </span>
        </div>
      </template>

      <template v-slot:append="{ item }" class="">
        <div v-if="item.children && item.children.length === 0">
          <v-switch
            v-if="selCmp === 'toogle'"
            :input-value="item.isSelected"
            @change="selection_change(item, $event)"
            dense
          />
          <v-checkbox
            v-if="selCmp === 'checkbox'"
            :input-value="item.isSelected"
            @change="selection_change(item, $event)"
            :disabled="item.isDisabled"
            dense
            hide-details
            class="mt-0 pt-0"
          />
        </div>
      </template>
    </v-treeview>
  </div>
</template>

<script>
import { collectIdsWithSelectedChildren } from "@/managers/tagsToTree";

const SelCmpEnum = Object.freeze({
  CH: "checkbox",
  TG: "toggle"
});

export default {
  name: "NGTTree",
  components: {},
  props: {
    loadParams: {
      type: Object,
      default: null
    },
    topLevelIndex: {
      type: Number,
      default: null
    },
    selCmp: {
      type: String,
      default: "toggle",
      validator: v => Object.values(SelCmpEnum).includes(v)
    }
  },
  data() {
    return {
      openedItems: [],
      activeItems: [],
      isLoading: false,
      error: "",
      lastOffScreenElementKey: null
    };
  },
  computed: {
    items() {
      if (this.$store.state.entityTags.items.length === 0) return [];

      if (this.topLevelIndex === null)
        return this.$store.state.entityTags.items;

      return this.$store.state.entityTags.items[this.topLevelIndex].children;
    }
  },
  async created() {
    await this.loadData();
  },
  async beforeDestroy() {
    this.$store.dispatch("entityTags/cleanUp");
  },
  methods: {
    async loadData() {
      try {
        this.isLoading = true;
        await this.$store.dispatch("entityTags/load", this.loadParams);
        this.openedItems = collectIdsWithSelectedChildren(
          this.$store.state.entityTags.items
        );
        // console.log(this.$store.state.entityTags.items, this.openedItems);
      } catch (error) {
        this.error = "Error";
      } finally {
        this.isLoading = false;
      }
    },
    onOpen: function(event) {
      this.openedItems = event;
    },
    onActive: function(event) {
      this.activeItems = event;
    },
    async selection_change(item, v) {
      item.isSelected = v;
      item.isDisabled = true;
      if (item.isSelected) {
        const error = await this.$store.dispatch(
          "entityTags/selectNode",
          item.id
        );
        if (error) {
          item.isSelected = false;
        }
      } else {
        const error = await this.$store.dispatch(
          "entityTags/unselectNode",
          item.id
        );
        if (error) {
          item.isSelected = true;
        }
      }
      item.isDisabled = false;
    },
    hasTranslation(key) {
      return this.$te(key);
    },
    highlightElement(id) {
      this.$emit("highlight:el", id);
    }
  }
};
</script>
